import type { APCountry } from '~/modules/root';

export const fullCountryNameFromCode = (countryCode: APCountry) => {
    switch (countryCode) {
        case 'AU':
            return 'Australia';
        case 'NZ':
        default:
            return 'New Zealand';
    }
};

export const localeFromCode = (countryCode: APCountry) => {
    switch (countryCode) {
        case 'AU':
            return 'en_AU';
        case 'NZ':
        default:
            return 'en_NZ';
    }
};
