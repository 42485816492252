import { createMetaDescription } from './create-meta-description';
import { localeFromCode } from './localisation';
import invariant from 'tiny-invariant';
import type { MetaArgs, MetaDescriptor } from '@remix-run/node';
import { getMetaBaseUrl } from './create-meta-base-url';
import { getRouteData } from '~/modules/root/util/get-route-data';
import type { loader } from '~/modules/professional/page/loaders/professional-page-loader.server';

interface CreateOGProps {
    meta: MetaArgs;
    title: string;
    facebookSocialImage?: string;
    description?: string;
    type?: string;
}

export const createOGTags = (props: CreateOGProps): MetaDescriptor[] => {
    const { title, description, facebookSocialImage, meta, type } = props;
    const { rootData } = getRouteData(meta.matches);
    invariant(rootData, 'Root Module is required for SEO');
    const countryCode = rootData.localisation.country;
    const baseUrl = getMetaBaseUrl(meta);

    return [
        { property: 'og:title', content: title },
        { property: 'og:locale', content: localeFromCode(countryCode) },
        // Note - og:url will not update on route changes, but it should be fine for
        // og links being crawled or shared (i.e. a full SSR)
        {
            property: 'og:url',
            content: baseUrl + meta.location.pathname,
        },
        {
            property: 'og:description',
            content: description ?? createMetaDescription([]),
        },
        {
            property: 'og:image',
            content: facebookSocialImage ?? fallbackOGSocialImage(meta),
        },
        {
            property: 'og:type',
            content: type ?? 'website',
        },
    ];
};

export const fallbackOGSocialImage = (
    props: MetaArgs,
    fallbackImage?: string | null
): string => {
    const baseUrl = getMetaBaseUrl(props);
    if (!fallbackImage) {
        return baseUrl + '/_resources/themes/archipro2/images/social_share.jpg';
    }
    return baseUrl + fallbackImage;
};

export const getProfessionalBannerByPriority = (
    props: MetaArgs<typeof loader>
): string => {
    const { matches, data } = props;
    const banners = data?.banners;
    const fallback = '/_resources/themes/archipro2/images/social_share.jpg';
    if (!banners || banners.length === 0) return fallback;

    // Get Professional page banner segments by location
    const currentRoute = matches[matches.length - 1]?.pathname;
    if (!currentRoute) {
        return fallback;
    }
    const currentProfessionalPath =
        currentRoute
            .split('/')
            .filter((path) => path)
            .pop() ?? '';

    // Select banner by priority: path param segment banner > default banner > first banner > fallback social image
    let banner;
    if (currentProfessionalPath) {
        // e.g segment === info, use this professional segment banner image
        banner = banners.find(
            (banner) => banner.bannerSegment === currentProfessionalPath
        );
    }
    if (!banner) {
        // otherwise segment === null, professional default image
        banner = banners.find((banner) => !banner.bannerSegment);
    }
    if (!banner) {
        // otherwise use first image we have access to
        banner = banners[0] ?? null;
    }
    const bannerUrl = banner?.libraryImageFile?.DetailBannerStandard?.Desktop;

    if (!bannerUrl) return fallback;

    return bannerUrl;
};
